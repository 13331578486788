import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { HelperService } from '../../services/helper.service';
import { AuthLoginService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";


@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

	loginForm: any;
	constructor(
		public fb: FormBuilder,
		public helper: HelperService,
		public Auth: AuthLoginService,
		public router: Router,
		private translate: TranslateService,
		private authService: AuthService,
		private acRouter : ActivatedRoute
	) { }

	ngOnInit() {
		var obj = this;

		obj.loginForm = obj.fb.group({
			username: obj.fb.control('', Validators.required),
			password: obj.fb.control('', Validators.required)
		});
		
		obj.acRouter.queryParams.subscribe(
			params => {

				if (typeof (params['oauth_token']) != 'undefined' && typeof (params['oauth_verifier']) != 'undefined')
				{
					// authentication of twitter successful
					obj.Auth.twitterLogin(params).subscribe(
						response => {
							if (response['status'] == 'success') {
								var userObj = response["data"].user;
								localStorage.setItem("_user_object", JSON.stringify(userObj));

								location.reload();
							}else{
								Swal.fire(response['message'])
							}
						}
					)
				}
			}
		)

		

		this.authService.authState.subscribe((user) => {
			console.log('Social user login : ', user);
			if(user)
			{
				localStorage.setItem("_social_login", 'facebook');
				var data = {
					first_name: user['firstName'] + ' ' + user['lastName'],
					username: typeof (user['email']) != 'undefined' ? user['email'] : user['id'],
					login_type: 'facebook'
				};

				obj.Auth.authenticate(data).subscribe(
					response => {
						if (response["status"] == "error") {
							Swal.fire(response["message"]);
						} else {
							// check if admin or business user is logging
							var userObj = response["data"].user;
							localStorage.setItem("_user_object", JSON.stringify(userObj));

							location.reload();
						}
					}
				)
			}
		});
	}

	ngOnDestroy(){}

	onSubmit() {
		var obj = this;
		// check if the form is valid or not
		if (obj.loginForm.valid) {
			// form is validated and call the api
			obj.Auth.authenticate(obj.loginForm.value).subscribe(
				response => {
					if (response["status"] == "error") {
						Swal.fire(response["message"]);
					} else {
						// check if admin or business user is logging
						var userObj = response["data"].user;
						localStorage.setItem("_user_object", JSON.stringify(userObj));

						location.reload();
						/*
						if (userObj["user_type"] == "admin" || userObj["user_type"] == "manager" || userObj["user_type"] == "barista") {
							obj.router.navigate(["/administrator"]);
						} else if (userObj["user_type"] == "event_organiser") {
							obj.router.navigate(["/event-organiser"]);
						} else if (userObj["user_type"] == "registered") {
							obj.router.navigate(["/guest"]);
						}
						*/
					}
				}
			)
		} else {
			obj.helper.validateFormFields(obj.loginForm);
		}
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	twitterLogin()
	{
		var obj = this;

		
		obj.Auth.getTwitterRequestToken().subscribe(
			(response : any) => {
				console.log(response);

				if(response['status'] == 'success')
				{
					window.location.href = 'https://api.twitter.com/oauth/authenticate?' + response['data'];
				}else{
					Swal.fire(response['message']);
				}
			}
		)
	}

	facebookLogin(): void {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
	}
}
