// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    isMockEnabled: false, // You have to switch this, when your real back-end is done
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    socket_url: 'https://projectcoffeeapp.com:3003',
    api_url: 'https://projectcoffeeapp.com:3000/api/v1',
    product_images: 'http://projectcoffeeapp.com/assets/uploads/coffee_images/',
    games_images: 'http://projectcoffeeapp.com/assets/uploads/games/',
    prize_images: 'http://projectcoffeeapp.com/assets/uploads/prizes/',
    card_images: 'http://projectcoffeeapp.com/assets/uploads/cards/',
    profile_picture: 'http://projectcoffeeapp.com/assets/uploads/user_profiles/',
    business_card: 'http://projectcoffeeapp.com/assets/uploads/business_cards/',
    event_image: 'http://projectcoffeeapp.com/assets/uploads/events/',
    bug_reports: 'http://projectcoffeeapp.com/assets/uploads/bug_reports/',
    table_images: 'http://projectcoffeeapp.com/assets/uploads/table_images/',
    artist_images: 'http://projectcoffeeapp.com/assets/uploads/user_profiles/',
    notification_mode: 'development',
    facebook_app_id: '1376754445800165'
};
