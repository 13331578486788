export class GuestMenuConfig {
	public defaults: any = {
		header: {
			self: {},
			'items': []
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon-dashboard',
					page: '/guest',
					bullet: 'dot',
					module: 'statistics'
				},
				{
					title: 'Wallet',
					root: true,
					icon: 'fa fa-wallet',
					page: '/guest/wallet',
					bullet: 'dot',
					module: 'wallet'
				},
				{
					title: 'Orders',
					root: true,
					icon: 'fa fa-shopping-cart',
					page: '/guest/orders',
					bullet: 'dot',
					module: 'orders'
				},
				{
					title: 'Bookings',
					root: true,
					icon: 'fa fa-book',
					page: '/guest/bookings',
					bullet: 'dot',
					module: 'orders'
				},
				{
					title: 'Game',
					root: true,
					icon: 'fa fa-gamepad',
					page: '/guest/board-game',
					bullet: 'dot',
					module: 'games'
				},
				{
					title: 'Cards History',
					root: true,
					icon: 'fa fa-address-book',
					page: '/guest/card-logs',
					bullet: 'dot',
					module: 'cardslog'
				},
				{
					title: 'History',
					root: true,
					icon: 'flaticon-diagram',
					page: '/guest/history',
					bullet: 'dot',
					module: 'cardslog'
				}
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
