export class PageConfig {
	public defaults: any = {
		administrator: {
			page: {
				'title': 'Dashboard',
				'desc': 'Latest updates and statistic charts'
			},
			settings: {
				page: {
					'title': 'Settings',
					'desc': 'Set the required settings for the platform'
				},
			},
			'news-list': {
				page: {
					'title': 'Whats new',
					'desc': 'Below is the list of what\'s new items'
				},
			},
			'news': {
				page: {
					'title': 'Whats new',
					'desc': 'Use below form to add/edit new item'
				},
			},
			'business-cards':{
				page: {
					'title': 'Business cards',
					'desc': 'List of cards added by the customers'
				},
			},
			'users':{
				page: {
					'title': 'Users',
					'desc': 'List of all users registered on the platform'
				}
			},
			'user':{
				page:{
					'title':'User',
					'desc':'Use below form to add or update user information'
				}
			},
			categories: {
				page:{
					'title':'Categories',
					'desc':'Below is the list of menus that appears on the mobile app'
				}
			},
			category: {
				page: {
					'title': 'Category',
					'desc': 'Use below form to create menu for the app'
				}
			},
			products: {
				page: {
					'title': 'Products',
					'desc': 'Below is the list of coffee products'
				}
			},
			product: {
				page: {
					'title': 'Product',
					'desc': 'Use below form to add coffee products'
				}
			},
			addons: {
				page: {
					'title': 'Addons',
					'desc': 'List of addons of the coffee'
				}
			},
			addon: {
				page: {
					'title': 'Addon',
					'desc': 'Use below form to add addon data.'
				}
			},
			'addon-options':{
				page: {
					'title': 'Addons options',
					'desc': 'List of addons options of the coffee'
				}
			}, 
			'addon-option': {
				page: {
					'title': 'Addon option',
					'desc': 'Use below form to add/update the addon option'
				}
			},
			tables: {
				page: {
					'title': 'Tables',
					'desc': 'List of tables availabel in the shop'
				}
			},
			table: {
				page: {
					'title': 'Table',
					'desc': 'Use below form to add/update table'
				}
			},
			'bookings': {
				page: {
					'title': 'Bookings',
					'desc': 'List of tables booked by the customers.'
				}
			},
			'event-locations':{
				page: {
					'title': 'Events Locations',
					'desc': 'List of locations for the events'
				}
			},
			'notifications': {
				page: {
					'title': 'Notifications',
					'desc': 'List of all notifications delivered to the customers'
				}
			},
			'notification': {
				page: {
					'title': 'Notification',
					'desc': 'Use below form to send notification to the customers.'
				}
			},
			'bugs': {
				page: {
					'title': 'Bugs List',
					'desc': 'List of all bugs reported by the customers'
				}
			},
			'rewards': {
				page: {
					'title': 'Rewards',
					'desc': 'List of all rewards that will be given to the customers'
				}
			},
			'prizes': {
				page: {
					'title': 'Prizes',
					'desc': 'List of all prizes of different games.'
				}
			},
			'spinwheel': {
				page: {
					'title': 'Spin wheel game',
					'desc': 'List of all spins available for spin wheel game.'
				}
			},
			'spin': {
				page: {
					'title': 'Spin wheel card',
					'desc': 'Use below form to create spin card.'
				}
			},
			'contests': {
				page: {
					'title': 'Contests',
					'desc': 'Below are the list of contests going on.'
				}
			},
			'contest': {
				page: {
					'title': 'Contest',
					'desc': 'Use below form to create contest.'
				}
			},
			'cards': {
				page: {
					'title': 'Cards',
					'desc': 'List of cards for the gameboard game.'
				}
			},
			'card': {
				page: {
					'title': 'Card',
					'desc': 'Use the below form to create card for the board game.'
				}
			},
			'card-logs': {
				page: {
					'title': 'Cards History',
					'desc': 'Use the below form to create card for the board game.'
				}
			},
			'winners': {
				page: {
					'title': 'Game winners',
					'desc': 'List of game winners.'
				}
			},
			orders: {
				page: {
					'title': 'Orders',
					'desc': 'List of orders'
				}
			},
			order: {
				page: {
					'title': 'Order',
					'desc': 'Below is the detail of order'
				}
			},
			account: {
				page: {
					'title': 'Account Settings',
					'desc': 'Use below form to update your account settings'
				}
			},
			'sms-list': {
				page: {
					'title': 'SMS',
					'desc': 'Bellow shows the list of sms sent through the platform'
				}
			},
			'sms': {
				page: {
					'title': 'SMS',
					'desc': 'Use below form to send sms to the users'
				}
			},
			'newsletters': {
				page: {
					'title': 'News-letters',
					'desc': 'Below is the list of news letters'
				}
			},
			'newsletter': {
				page: {
					'title': 'News-letter',
					'desc': 'Use below form to create news letter'
				}
			},
			'mailing-lists': {
				page: {
					'title': 'Mailing List',
					'desc': 'Below shows the list of mailing list for newsletters'
				}
			},
			'mailing-list': {
				page: {
					'title': 'Mailing List',
					'desc': 'Use below list to create mail list for newsletters'
				}
			},
			'subscribers': {
				page: {
					'title': 'Subscribers',
					'desc': 'Below shows the list of subscribers'
				}
			},
			'vouchers': {
				page: {
					'title': 'Gift Vouchers',
					'desc': 'Below is the list of gift vouchers generated by you.'
				}
			},
			'voucher': {
				page: {
					'title': 'Gift Vouchers',
					'desc': 'Use below form to generate gift vouchers for the users.'
				}
			},
			'track-vouchers': {
				page: {
					'title': 'Voucher tracker',
					'desc': 'Below is the list of vouchers redeemed by the users.'
				}
			},
			'poster': {
				page: {
					'title': 'Poster',
					'desc': 'Use below form to upload poster.'
				}
			},
			'posters': {
				page: {
					'title': 'Posters',
					'desc': 'Below is the list of posters.'
				}
			},
			'track-poster': {
				page: {
					'title': 'Poster Histroy',
					'desc': 'Below shows the list of users who have scaned the poster.'
				}
			},
			'messages': {
				page: {
					'title': 'Messages',
					'desc': 'Below shows the list of messages deliverd to the users from platform.'
				}
			},
		},
		
		error: {
			404: {
				page: {title: '404 Not Found', desc: '', subheader: false}
			},
			403: {
				page: { title: '403 Access Forbidden', desc: '', subheader: false }
			}
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}
