import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, finalize, delay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Injector, Injectable } from '@angular/core';
import { HelperService } from './services/helper.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		public injector : Injector,
		public router : Router
	){}
	
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		var obj = this;
		const loaderService = this.injector.get(HelperService);

		var user = localStorage.getItem("_user_object");
		if (typeof (user) === 'string' && user){
			user = JSON.parse(user);
			if (Object.keys(user).length > 0)
			{
				request = request.clone({
					setHeaders: {
						Authorization: user["access_token"],
						device_id: user["device_id"]
					}
				});
			}
		}

		request = request.clone({
			setHeaders: {
				app_mode: environment.notification_mode,
				user_timezone : 'USA/Pasific'
			}
		});
		loaderService.show(true);
		return next.handle(request)
		.pipe(
			map(response => {
				if(typeof response["body"] != "undefined")
				{
					if(response["body"].status == "error")
					{
						Swal.fire(response["body"].message);
					}
				}
				return response;
			}),
			catchError((error: HttpErrorResponse) => {
				loaderService.show(false);
				let errorMessage = '';
				if (error.error instanceof ErrorEvent) {
					// client-side error
					errorMessage = `Error: ${error.error.message}`;
				} else {
					// server-side error
					errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
				}
				if(error.status == 0)
				{
					Swal.fire("Server is down, please try again later or contact the web administrator");
				}else if(error.status == 401){
					localStorage.removeItem("_user_object");
					Swal.fire(error.error.message).then(
						() => {
							obj.router.navigate(['/']);
						}
					);
				}else if(error.status == 403){
					Swal.fire(error.error.message).then(
						() => {
							obj.router.navigate(['/']);
						}
					);
				}else{
					Swal.fire(errorMessage);
				}
				return throwError(errorMessage);
			}),
			finalize(() => {
				loaderService.show(false);
			})
		)
	}
}