import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  	providedIn: 'root'
})
export class AuthLoginService {
	api : any;
	constructor(
		public http : HttpClient
	) { 
		this.api 	=	environment.api_url;
	}
	
	authenticate(data : any)
	{
		var obj 	=	this;

		
		if(typeof(data.login_type) == 'undefined')
		{
			data.login_type = "app";
		}

		data.device_type = 'desktop';
		console.log(data);
		return obj.http.post(obj.api+"/auth",data);
	}

	getTwitterRequestToken()
	{
		var obj = this;
		return obj.http.get(obj.api + '/auth/twitter-auth-token');
	}

	twitterLogin(data)
	{
		var obj = this;
		return obj.http.post(obj.api + '/auth/twitter/access_token', data);
	}
}
