import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HelperService } from '../services/helper.service';
@Injectable({
	providedIn: 'root'
})
export class RolesGuard implements CanActivate {
	constructor(private helper: HelperService) {
	}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        var access_roles = this.helper.accessRoles();
        
		if (access_roles['user_type'] == 'admin') {
			return true;
        }
        
        
        if (typeof (route.data.access_role) == 'undefined') {
			return false;
        }
        
        
        var role = route.data.access_role;

        if (role == 'statistics' || role == 'dashboard') {
            return true;
        }

		if (typeof (access_roles[role]) == 'undefined') {
			return false;
        }
        
        
		if (access_roles[role]) {
			return true;
        } 
        
        return false;
	}
}
