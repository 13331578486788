export class MenuConfig {
    public defaults: any = {
        header: {
            self: {},
            'items': []
        },
        aside: {
            self: {},
            items: [
                {
                    title: 'Dashboard',
                    root: true,
                    icon: 'flaticon2-architecture-and-city',
                    page: '/administrator',
                    bullet: 'dot',
                    module: 'statistics'
                },
                {
                    title: 'Settings',
                    root: true,
                    icon: 'flaticon-settings-1',
                    page: '/administrator/settings',
                    bullet: 'dot',
                    module: 'settings'
                },
                {
                    title: 'Whats new',
                    root: true,
                    icon: 'flaticon-open-box',
                    page: '/administrator/news-list',
                    bullet: 'dot',
                    module: 'settings'
                },
                {
                    title: 'Business Cards',
                    root: true,
                    icon: 'flaticon-list-1',
                    page: '/administrator/business-cards',
                    module: 'business_cards'
                },
                {
                    title: 'Users',
                    root: true,
                    icon: 'flaticon-users',
                    page: '/administrator/users',
                    module: 'user'
                },
                {
                    title: 'Categories',
                    root: true,
                    icon: 'flaticon-tabs',
                    page: '/administrator/categories',
                    module: 'categories'
                },
                {
                    title: 'Products',
                    root: true,
                    icon: 'flaticon-business',
                    bullet: 'users',
                    module: 'products',
                    submenu : [
                        {
                            title: 'Addons',
                            root: true,
                            icon: 'flaticon-more-v6',
                            page: '/administrator/addons',
                            module: 'products'
                        },
                        {
                            title: 'Products',
                            root: true,
                            icon: 'flaticon-business',
                            page: '/administrator/products',
                            module: 'products'
                        },
                    ]
                },
                {
                    title: 'Orders',
                    root: true,
                    icon: 'flaticon-shopping-basket',
                    page: '/administrator/orders',
                    module: 'orders'
                },
                {
                    title: 'Bookings',
                    root: true,
                    icon: 'flaticon-graphic',
                    bullet: 'users',
                    module: 'bookings',
                    submenu: [
                        {
                            title: 'Spaces',
                            root: true,
                            icon: 'flaticon-network',
                            page: '/administrator/tables',
                            module: 'bookings'
                        },
                        {
                            title: 'Spaces Design',
                            root: true,
                            icon: 'flaticon-network',
                            page: '/layouts',
                            module: 'bookings'
                        },
                        {
                            title: 'Bookings',
                            root: true,
                            icon: 'flaticon-doc',
                            page: '/administrator/bookings',
                            module: 'bookings'
                        },
                    ]
                },
                {
                    title: 'Transactions',
                    root: true,
                    icon: 'flaticon-squares',
                    bullet: 'users',
                    module: 'transactions',
                    submenu: [
                        {
                            title: 'Wallet Transactions',
                            root: true,
                            icon: 'flaticon-coins',
                            page: '/administrator/wallet-transactions',
                            module: 'transactions'
                        },
                        {
                            title: 'Payments Transactions',
                            root: true,
                            icon: 'flaticon-layers',
                            page: '/administrator/payments-transactions',
                            module: 'transactions'
                        },
                    ]
                },
                {
                    title: 'Events',
                    root: true,
                    icon: 'flaticon-presentation',
                    bullet: 'users',
                    module: 'events',
                    submenu: [
                        {
                            title: 'Locations',
                            root: true,
                            icon: 'flaticon-bus-stop',
                            page: '/administrator/event-locations',
                            module: 'events'
                        },
                        {
                            title: 'Events',
                            root: true,
                            icon: 'flaticon-presentation',
                            page: '/administrator/events',
                            module: 'events'
                        },
                    ]
                },
                {
                    title: 'Messages',
                    root: true,
                    icon: 'flaticon-alert',
                    page: '/administrator/messages',
                    module: 'notifications'
                },
                {
                    title: 'SMS',
                    root: true,
                    icon: 'flaticon-paper-plane',
                    page: '/administrator/sms-list',
                    module: 'notifications'
                },
                {
                    title: 'Games',
                    root: true,
                    icon: 'flaticon-imac',
                    bullet: 'users',
                    module: 'games',
                    submenu: [
                        {
                            title: 'Prizes',
                            root: true,
                            icon: 'flaticon2-link',
                            page: '/administrator/prizes',
                            module: 'games'
                        },
                        {
                            title: 'Spin Wheel',
                            root: true,
                            icon: 'flaticon2-link',
                            page: '/administrator/spinwheel',
                            module: 'games'
                        },
                        {
                            title: 'Contests',
                            root: true,
                            icon: 'flaticon2-link',
                            page: '/administrator/contests',
                            module: 'games'
                        },
                        {
                            title: 'Cards',
                            root: true,
                            icon: 'flaticon2-link',
                            page: '/administrator/cards',
                            module: 'games'
                        },
                        {
                            title: 'Cards Logs',
                            root: true,
                            icon: 'flaticon2-link',
                            page: '/administrator/card-logs',
                            module: 'games'
                        },
                        {
                            title: 'Winners',
                            root: true,
                            icon: 'flaticon2-link',
                            page: '/administrator/winners',
                            module: 'games'
                        },
                    ]
                },
                {
                    title: 'Rewards',
                    root: true,
                    icon: 'flaticon-gift',
                    page: '/administrator/rewards',
                    module: 'rewards'
                },
                {
                    title: 'Gift Cards',
                    root: true,
                    icon: 'flaticon-gift',
                    page: '/administrator/giftcards',
                    module: 'giftcards'
                },
                {
                    title: 'Soundtracks',
                    root: true,
                    icon: 'flaticon-presentation',
                    bullet: 'soundtracks',
                    module: 'soundtracks',
                    submenu: [
                        {
                            title: 'Soundtrack List',
                            root: true,
                            icon: 'flaticon-bus-stop',
                            page: '/administrator/soundtracks',
                            module: 'soundtracks'
                        },
                        {
                            title: 'Soundtrack Setting',
                            root: true,
                            icon: 'flaticon-presentation',
                            page: '/administrator/soundtrack-settings',
                            module: 'soundtrack-settings'
                        },
                    ]
                },
                {
                    title: 'Temi',
                    root: true,
                    icon: 'flaticon-imac',
                    page: '/administrator/temi/add-location',
                    module: 'temi'
                },
                {
                    title: 'Temi Voice',
                    root: true,
                    icon: 'flaticon-imac',
                    page: '/administrator/temivoice',
                    module: 'temivoice'
                },
                {
                    title: 'Queue Requests',
                    root: true,
                    icon: 'flaticon-imac',
                    page: '/administrator/queue-requests',
                    module: 'queue-requests'
                },
                {
                    title: 'Newsletter',
                    root: true,
                    icon: 'flaticon2-new-email',
                    bullet: 'dot',
                    module: 'newsletter',
                    submenu: [
                        {
                            title: 'Newsletters',
                            page: '/administrator/newsletters',
                            module: 'newsletter'
                        },
                        {
                            title: 'Mailing List',
                            page: '/administrator/mailing-lists',
                            module: 'newsletter'
                        },
                        {
                            title: 'Subscribers',
                            page: '/administrator/subscribers',
                            module: 'newsletter'
                        },
                    ]
                },
                {
                    title: 'Vouchers',
                    root: true,
                    icon: 'fa fa-gift',
                    bullet: 'dot',
                    module: 'giftcard',
                    submenu: [
                        {
                            title: 'Vouchers',
                            page: '/administrator/vouchers',
                            module: 'newsletter'
                        }
                    ]
                },
                {
                    title: 'Posters',
                    root: true,
                    icon: 'flaticon2-photograph',
                    page: '/administrator/posters',
                    module: 'posters'
                },
                {
                    title: 'Bug reports',
                    root: true,
                    icon: 'flaticon-statistics',
                    page: '/administrator/bugs',
                    module: 'bug_reports'
                },
                { section: '&nbsp;' },
            ]
        },
    };

    public get configs(): any {
        return this.defaults;
    }
}
