export class GuestPageConfig {
	public defaults: any = {
		'guest': {
			page: {
				'title': 'Dashboard',
				'desc': 'Latest updates and statistic'
			},
			'card-logs': {
				page: {
					'title': 'Cards History',
					'desc': 'Below shows the list of card generated for you.'
				},
			},
			'event': {
				page: {
					'title': 'Event',
					'desc': 'Use below form to create an event!'
				},
			},
			'account': {
				page: {
					'title': 'Account Settings',
					'desc': 'Use below form to update your account settings'
				}
			},
			'profile': {
				page: {
					'title': 'Profile',
					'desc': 'Use below form to update your profile details'
				}
			},
			'wallet': {
				page: {
					'title': 'Wallet',
					'desc': 'Below is the list of all wallet transaction'
				}
			},
			'board-game': {
				page: {
					'title': 'Play the game',
					'desc': 'Play the game by getting a card from us'
				}
			},
			'orders': {
				page: {
					'title': 'My orders',
					'desc': 'Below shows the list of orders.'
				}
			},
			'bookings': {
				page: {
					'title': 'My bookings',
					'desc': 'Below shows the list of tables booked by you.'
				}
			},
			'gift-cards': {
				page: {
					'title': 'Gift cards',
					'desc': 'Below shows the list of gift cards mailed by you.'
				}
			},
			'gift-card': {
				page: {
					'title': 'Gift cards',
					'desc': 'Use below form to generate gift cards to the user.'
				}
			},
			'history': {
				page: {
					'title': 'History',
					'desc': 'Below shows the logs of your activity.'
				}
			},
			'order': {
				page: {
					'title': 'Order details',
					'desc': 'Below is the detail information of your order.'
				}
			},
		},
		
		error: {
			404: {
				page: {title: '404 Not Found', desc: '', subheader: false}
			},
			403: {
				page: { title: '403 Access Forbidden', desc: '', subheader: false }
			}
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}
