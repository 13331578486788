import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  	providedIn: 'root'
})
export class LoginGuard implements CanActivate{

	constructor(
		private _router: Router) {
	}
  
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
	
		var userObject 	=	localStorage.getItem("_user_object");
		userObject 	=	JSON.parse(userObject);
		
		if(!userObject)
		{
			return true;
		}
		
		if (userObject["user_type"] == "admin" || userObject["user_type"] == "manager" || userObject["user_type"] == "barista"){
			this._router.navigate(['/administrator']);
			return false;
		}else if(userObject["user_type"] == "registered"){
			return this._router.navigate(['/guest']);
			return false;
		}else if(userObject["user_type"]	==	"event_organiser"){
			this._router.navigate(['/event-organiser']);
			return false;
		}
	  	return true;
	}
}
