import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  	providedIn: 'root'
})
export class AdminGuard implements CanActivate{

	constructor(
		private _router: Router) {
	}
  
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
	
		var userObject 	=	localStorage.getItem("_user_object");
		userObject 	=	JSON.parse(userObject);
		if(!userObject)
		{
			return this._router.navigateByUrl('/auth/login');
		}

		if (userObject["user_type"] == "admin" || userObject["user_type"] == "manager" || userObject["user_type"]== "barista"){
			return true;
    	}

		return this._router.navigateByUrl('/auth/login');
	}
}
