// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'angularx-social-login';
// NGRX

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	//user$: Observable<User>;
	_user : any;
	@Input() avatar: boolean = true;
	@Input() greeting: boolean = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private router : Router,
		private AuthService : AuthService
	) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		var user = localStorage.getItem('_user_object');
		//this.user$ = this.store.pipe(select(currentUser));
		this._user = JSON.parse(user);
		console.log('user object : ',this._user);
	}

	/**
	 * Log out
	 */
	logout() {
		
		var _social_login = localStorage.getItem('_social_login');
		if (_social_login)
		{
			this.AuthService.signOut();
		}
		
		//this.store.dispatch(new Logout());
		localStorage.removeItem("_user_object");
		localStorage.removeItem("layoutConfig");
		this.router.navigate(["/auth/login"]);
	}
}
