import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  	providedIn: 'root'
})
export class EventOrganiserGuard implements CanActivate{

	constructor(
		private _router: Router) {
	}
  
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
	
		var userObject 	=	localStorage.getItem("_user_object");
		userObject 	=	JSON.parse(userObject);

		if(!userObject)
		{
			return false;
		}

		if(userObject["user_type"] == "event_organiser"){
			return true;
    	}
    	return this._router.navigate(['/']);
	}
}

