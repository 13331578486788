export class EventMenuConfig {
	public defaults: any = {
		header: {
			self: {},
			'items': []
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon-dashboard',
					page: '/event-organiser',
					bullet: 'dot',
					module: 'events'
				},
				{
					title: 'Events',
					root: true,
					icon: 'flaticon-event-calendar-symbol',
					page: 'events',
					bullet: 'dot',
					module: 'events'
				},
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
