import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})

export class NewsletterService {

	api : string;
	constructor(
		public http : HttpClient
	) { 
		this.api 	=	environment.api_url;
	}

	getFormData() {
		var obj = this;
		return obj.http.get(obj.api + "/newsletter/mailing-form");
	}

	save(data) {
		var obj = this;
		return obj.http.post(obj.api + "/newsletter", data);
	}

	get(recordId) {
		var obj = this;
		return obj.http.get(obj.api + "/newsletter/" + recordId);
	}

	list(dataTableParameters) {
		var obj = this;
		return obj.http.post(obj.api + "/newsletter/list", dataTableParameters);
	}
	

	delete(recordId) {
		var obj = this;
		return obj.http.delete(obj.api + "/newsletter/" + recordId);
	}

	update(data) {
		var obj = this;
		return obj.http.put(obj.api + "/newsletter", data);
	}

	onChangeStatus(data) {
		var obj = this;
		return obj.http.post(obj.api + "/newsletter/change-status", data);
	}

	getMailingList(dataTableParameters) {
		var obj = this;
		return obj.http.post(obj.api + "/newsletter/mailing-list", dataTableParameters);
	}

	saveMailingList(data) {
		var obj = this;
		return obj.http.post(obj.api + "/newsletter/save-mailing-list", data);
	}

	deleteMailingList(recordId) {
		var obj = this;
		return obj.http.delete(obj.api + "/newsletter/mailing-list/" + recordId);
	}

	onChangeMailingStatus(data) {
		var obj = this;
		return obj.http.post(obj.api + "/newsletter/mailing-list/change-status", data);
	}

	getMailingItem(recordId) {
		var obj = this;
		return obj.http.get(obj.api + "/newsletter/mailing-list/" + recordId);
	}

	updateMailingList(data) {
		var obj = this;
		return obj.http.put(obj.api + "/newsletter/save-mailing-list", data);
	}

	subscribersList(dataTableParameters) {
		var obj = this;
		return obj.http.post(obj.api + "/newsletter/subscribers-list", dataTableParameters);
	}

}
