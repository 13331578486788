import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GuestService implements CanActivate {

	constructor(
		private _router: Router) {
	}
  
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
	
		var userObject 	=	localStorage.getItem("_user_object");
		userObject 	=	JSON.parse(userObject);

		if(!userObject)
		{
			return false;
		}

		/*
		if(userObject["user_type"] == "registered"){
			return true;
		}
		*/
		
		if (Object.keys(userObject).length > 0) {
			return true;
		}

    	this._router.navigate(['/']);
    
    	return false;
	}
}
