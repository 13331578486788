// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
import { AdminGuard, LoginGuard, EventOrganiserGuard, GuestService } from './guards';
import { AdminLoginGuard } from './guards/admin.login';

const routes: Routes = [
	{ 
		path: '', 
		canActivate: [AdminLoginGuard],
		children: [
			{
				path: '',
				loadChildren: './site/site.module#SiteModule'
			}
		]
	},
	
	{ 
		path: 'auth', 
		canActivate: [LoginGuard],
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) 
	},

	{
		path: 'administrator',
		component: BaseComponent,
		canActivate: [AdminGuard],
		children: [
			{
				path: '',
				loadChildren: './administrator/administrator.module#AdministratorModule'
			}
		]
	},

	{
		path: 'event-organiser',
		component: BaseComponent,
		canActivate: [EventOrganiserGuard],
		children: [
			{
				path: '',
				loadChildren: './eventorganiser/eventorganiser.module#EventOrganiserModule'
			}
		]
	},

	{
		path: 'guest',
		component: BaseComponent,
		canActivate: [GuestService],
		children: [
			{
				path: '',
				loadChildren: './users/users.module#UsersModule'
			}
		]
	},
	{
		path: 'layouts',
		children: [
			{
				path: '',
				loadChildren: './layouts/layout.module#LayoutModule'
			}
		]
	},
	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			'type': 'error-v6',
			'code': 403,
			'title': '403... Access forbidden',
			'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
		}
	},
	
	{
		path: 'error/404',
		component: ErrorPageComponent,
		data: {
			'type': 'error-v2',
			'code': 404,
			'title': '404, page not found',
			'desc': 'The page your are looking for does not exists!'
		}
	},
	{ path: '**', redirectTo: 'error/404', pathMatch: 'full'},
	
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
