export class EventPageConfig {
	public defaults: any = {
		'event-organiser': {
			page: {
				'title': 'Dashboard',
				'desc': 'Latest updates and statistic of events'
			},
			'events': {
				page: {
					'title': 'Events',
					'desc': 'Below is the list of events created by you.'
				},
			},
			'event': {
				page: {
					'title': 'Event',
					'desc': 'Use below form to create an event!'
				},
			},
			'account': {
				page: {
					'title': 'Account Settings',
					'desc': 'Use below form to update your account settings'
				}
			},
			'profile': {
				page: {
					'title': 'Profile',
					'desc': 'Use below form to update your profile details'
				}
			},
		},
		
		error: {
			404: {
				page: {title: '404 Not Found', desc: '', subheader: false}
			},
			403: {
				page: { title: '403 Access Forbidden', desc: '', subheader: false }
			}
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}
