// Angular
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';
// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';

@Component({
	selector: 'kt-widget15',
	templateUrl: './widget15.component.html',
	styleUrls: ['./widget15.component.scss'],
})
export class Widget15Component implements OnInit {
	chartLayout:any;
	// Public properties
	@Input() title: string;
	@Input() desc: string;
	@Input() data: { labels: string[]; datasets: any[] };
	@ViewChild('chart', {static: true}) chart: ElementRef;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (!this.data) {
			this.data = {
				datasets: [{
					backgroundColor: [
						this.layoutConfigService.getConfig('colors.state.success'),
						this.layoutConfigService.getConfig('colors.state.warning')],
					data: [0,0]
				}],

				// These labels appear in the legend and in the tooltips when hovering different arcs
				labels: [
					'Total orders amount',
					'Total booking amount'
				]
			};
		}

		this.initChartJS();
	}

	ngOnChanges()
	{
		console.log('this is chart : ',this.data);
		if (this.data){
			if (typeof (this.data.labels.length) != 'undefined' && this.data.labels.length > 0)
			{
				this.data = {
					labels: this.data.labels,
					datasets: [
						{
							backgroundColor: [
							this.layoutConfigService.getConfig('colors.state.danger'),
							this.layoutConfigService.getConfig('colors.state.warning')],
							data: this.data.datasets
						}
					]
				};
				this.chartLayout.destroy();
				this.initChartJS();
			}
			
			
		}
	}

	/** Init chart */
	initChartJS() {
		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html

		this.chartLayout = new Chart(this.chart.nativeElement, {
			type: 'doughnut',
			data: this.data,
			options: {
				title: {
					display: true,
				},
				tooltips: {
					intersect: false,
					mode: 'nearest',
					xPadding: 10,
					yPadding: 10,
					caretPadding: 10
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				barRadius: 4,
				scales: {
					xAxes: [{
						display: false,
						gridLines: false,
						stacked: true
					}],
					yAxes: [{
						display: false,
						stacked: true,
						gridLines: false
					}]
				},
				layout: {
					padding: {
						left: 0,
						right: 0,
						top: 0,
						bottom: 0
					}
				}
			}
		});
	}
}
